import { useParams, useLocation } from "react-router-dom"
import { isNil } from "lodash"
import BlogFull from "./BlogFull"
import BlogList from "./BlogList"

function Blog() {
  const { slug } = useParams();
  const location = useLocation();

  const getPageNumberFromQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page');
    return page;
  };

  const page = getPageNumberFromQuery() || '1';

  if (slug) {
    return <BlogFull slug={slug} />;
  } else {
    return <BlogList page={page} />;
  }
}

export default Blog