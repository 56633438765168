import { useState, useEffect } from "react"
import axios from "axios"
import parse from "html-react-parser"
import {isNil} from "lodash"

function Static() {
    const [page, setPage] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const slug = window.location.pathname.replace("/","")

    useEffect(() => {
        setLoading(true)
        setError(false)
        axios.get(`https://kavehalizadeh.com/api/pages/${slug}`)
        .then((response) => {
            setPage(response.data)
        })
        .catch((error) => {
            setError(true)
        })
        .finally(() => {
            setLoading(false)
        })
    }, [slug])

  return (
    <div>
        {loading && <div>Loading...</div>}
        {error && <div>Error</div>}
        {!loading && !error && page && (
            <div>
                <h2>{page.title}</h2>
                <img src={`/images/${page.image}`} alt={page.title} />
                <p>{parse(page.body)}</p>
            </div>
        )}
    </div>
    
  )
}

export default Static