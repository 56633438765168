import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Category from './pages/Category';
import Static from './pages/Static';
import './App.css';
import Header from './Header';
function App() {
  return (
    <Router>
    <div className="App">
      <div className="contrainer">
        <div className="row">
        <Header />
        </div>
        <div className="row mx-auto">
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route index element={<Home />} />
              <Route path="posts/:slug?" element={<Blog />} />
              <Route path="category/:id" element={<Category />} />
              <Route path="*" element={<Static />} />
            </Route>
          </Routes>
      </div>
      </div>
    </div>
    </Router>
  );
}

export default App;
