import { useState, useEffect } from "react"
import axios from "axios"
import { Link } from 'react-router-dom';
import { formatDate } from "../../functions"

function BlogList({page, category}) {
    const [posts, setPosts] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [pageCount, setPageCount] = useState(0);
    const currentPage = page;

    useEffect(() => {
        setLoading(true)
        setError(false)
        var url = "https://kavehalizadeh.com/api/posts?page="+page;
        if(category) {
            url += "&category="+category;
        }
        axios.get(url)
        .then((response) => {
            setPosts(response.data.data)
            setPageCount(Math.ceil(response.data.total/response.data.per_page))
        })
        .catch((error) => {
            setError(true)
        })
        .finally(() => {
            setLoading(false)
        })
    }, [page, category])

  return (
    <div>
        {loading && <div>Loading...</div>}
        {error && <div>Error</div>}
        {!loading && !error && (
            <div className="row">
                {posts && posts.map((post) => (
                    <div className="w-100 p-3">
                        <div className="card">
                            {post.image &&
                                <img style={{width: "100%", height: "300px", objectFit: "cover"}} src={"/images/" + post.image} alt="" />
                            }
                            <div className="card-body d-flex flex-column align-items-start">
                                <strong className="d-inline-block mb-2 text-primary">{post.category}</strong>
                                <h3 className="mb-0">
                                <Link to={"/posts/"+post.slug} className="text-dark">{post.title}</Link>
                                </h3>
                                <div className="mb-1 text-muted">{formatDate(post.created_at)}</div>
                                <p className="card-text mb-auto">{post.excerpt}</p>
                                <Link to={"/posts/"+post.slug}>ادامه...</Link>
                            </div>
                        </div>
                    </div>
                ))}
                <div>
                {pageCount > 1 && (
                    <ul className="pagination" style={{width:200}}>
                        {currentPage > 1 && (
                        <li className="page-item">
                            <Link className="page-link" to={`/posts?page=${Math.max(1, currentPage - 1)}`}>
                                &laquo;
                            </Link>
                        </li>
                        )}

                        {Array.from(Array(pageCount).keys())
                            .filter(pageNumber => 
                                pageNumber === 0 || 
                                pageNumber === 1 || 
                                pageNumber === pageCount - 2 || 
                                pageNumber === pageCount - 1)
                            .map((pageNumber, index, arr) => (
                                <>
                                    <li className="page-item" key={pageNumber}>
                                        <Link className="page-link" to={`/posts?page=${pageNumber + 1}`}>
                                            {pageNumber + 1}
                                        </Link>
                                    </li>

                                    {index < arr.length - 1 && arr[index + 1] - arr[index] > 1 && (
                                        <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                        </li>
                                    )}
                                </>
                            ))
                        }
                        {currentPage < pageCount && (
                        <li className="page-item">
                            <Link className="page-link" to={`/posts?page=${Math.min(pageCount, currentPage + 1)}`}>
                                &raquo;
                            </Link>
                        </li>
                        )}
                    </ul>
                )}

                </div>
            </div>
        )}
    </div>
    
  )
}

export default BlogList