import { useState, useEffect } from "react"
import axios from "axios"
import parse from "html-react-parser"
import { formatDate } from "../../functions"
import {Helmet} from "react-helmet";

function BlogFull({slug}) {
  const [post, setPost] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    
    useEffect(() => {
        setLoading(true)
        setError(false)
        axios.get(`https://kavehalizadeh.com/api/posts/${slug}`)
        .then((response) => {
            setPost(response.data)
        })
        .catch((error) => {
            setError(true)
        })
        .finally(() => {
            setLoading(false)
        })
    }, [slug])

  return (
    <div>
        {loading && <div>Loading...</div>}
        {error && <div>Error</div>}
        {!loading && !error && post && (
                <div className="blogFull">
                    <Helmet>
                      <title>{post.seo_title ? post.seo_title : post.title} | یادداشت های کاوه علیزاده</title>
                      {post.meta_description && 
                        <meta name="description" content={post.meta_description} />
                      }
                      {post.image &&
                        <meta property="og:image" content={"https://kavehalizadeh.com/images/"+post.image} />
                      }
                      <link rel="canonical" href={"https://kavehalizadeh.com/posts/"+slug} />
                    </Helmet>
                    <h2>{post.title}</h2>
                    <div className="date">{formatDate(post.created_at)}</div>
                    <img src={`/images/${post.image}`} alt={post.title} style={{width:"100%", height:"auto"}} className="mainImage" />
                    <br />
                    <p className="blogText">{parse(post.body)}</p>
                </div>
        )}
    </div>

  )
}

export default BlogFull