import {useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios'

function Header() {
  const [data, setData] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const {pathname} = useLocation();
  function getCategoryIdFromUrl(url, segment) {
      let parts = url.split('/');

      let index = parts.indexOf(segment);
      if(index > -1 && index < parts.length - 1){
          return parts[index + 1];
      }

      return null;
  }

  useEffect(() => {
    setCategoryId(getCategoryIdFromUrl(pathname, 'category'));
  }, [pathname])




  useEffect(() => {
    axios.get('https://kavehalizadeh.com/api/categories')
      .then(response => setData(response.data))
  }, [])
  
  return (
    <div>
      <div className="center">
        <img src={"/logo.png"} alt="Avatar" width="200" />
      </div>
      <Link to="/">
        <h1 className="center">یادداشت‌های کاوه علیزاده</h1>
      </Link>
      <div className="center">
        {data && data.map((item, index) => {
          return (
            <Link to={'/category/'+item.id}><span className={"tags"+(categoryId == item.id ? ' active' : '')} key={index}>{item.name}</span> </Link> 
          )
        })}
      </div>
      
    </div>
  )
}

export default Header