import React, { Fragment, useEffect, useState } from 'react'
import BlogList from '../Blog/BlogList'
import { useLocation } from "react-router-dom"
import { useParams } from "react-router-dom"

function Category() {

    const location = useLocation();
    const [categoryId, setCategoryId] = useState(null)
    const [page, setPage] = useState(null)
    const {id } = useParams();

    

    useEffect(() => {
        const getPageNumberFromQuery = () => {
            const searchParams = new URLSearchParams(location.search);
            const page = searchParams.get('page');
            return page;
        };
    
        setPage(getPageNumberFromQuery() || '1');
        setCategoryId(id);
    }, [id])
    
    return (
        <Fragment>
            {categoryId &&
                <BlogList category={categoryId} page={page} />
            }
        </Fragment>
    )
}

export default Category